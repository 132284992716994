import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/docs-src/src/layouts/MDXPageLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`path: '/guidelines/markup/'
title: 'Markup'`}</p>


    <h2>{`Conventions`}</h2>
    <p>{`Use SUIT classes (`}<a parentName="p" {...{
        "href": "https://suitcss.github.io/"
      }}>{`https://suitcss.github.io/`}</a>{`) when defining UI components in styles`}</p>
    <h2>{`Linting`}</h2>
    <p><a parentName="p" {...{
        "href": "lintron.io"
      }}>{`Lintron`}</a>{` is set up on most of our projects and provides style linting. Make sure to follow it's suggestions. If for some reason you object, leave a comment stating why.`}</p>
    <h2>{`Accessibility`}</h2>
    <p>{`We adhere to `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/intro/wcag"
      }}>{`WCAG`}</a>{` `}<a parentName="p" {...{
        "href": "https://www.wuhcag.com/wcag-checklist/"
      }}>{`AA`}</a>{` standards for web accessible content. Everything that’s on your website must work to defined standards. Where you’re writing code that’s not HTML, it must conform to HTML-like standards. This means that it will work with various assistive technologies.`}</p>
    <h3>{`Accessibility Tools`}</h3>
    <ul>
      <li parentName="ul">{`Contrast Plugin for Sketch: `}<a parentName="li" {...{
          "href": "http://www.getstark.co/"
        }}>{`http://www.getstark.co/`}</a></li>
      <li parentName="ul">{`WAVE Chrome Eval Plugin: `}<a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/wave-evaluation-tool/jbbplnpkjmmeebjpijfedlgcdilocofh?hl=en-US"
        }}>{`https://chrome.google.com/webstore/detail/wave-evaluation-tool/jbbplnpkjmmeebjpijfedlgcdilocofh?hl=en-US`}</a></li>
      <li parentName="ul">{`HTML Validation: `}<a parentName="li" {...{
          "href": "https://validator.w3.org/"
        }}>{`https://validator.w3.org/`}</a></li>
      <li parentName="ul">{`Chrome Developer Tools for Accessibility: `}<a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/accessibility-developer-t/fpkknkljclfencbdbgkenhalefipecmb?hl=en"
        }}>{`https://chrome.google.com/webstore/detail/accessibility-developer-t/fpkknkljclfencbdbgkenhalefipecmb?hl=en`}</a></li>
      <li parentName="ul">{`Contrast Eye Dropper: `}<a parentName="li" {...{
          "href": "https://usecontrast.com/"
        }}>{`https://usecontrast.com/`}</a></li>
    </ul>
    <h2>{`Leave comments`}</h2>
    <p>{`If styles or markup is complex, leave comments so that other can understand why things were done in a specific way`}</p>
    <p>{`If styles require things like `}<inlineCode parentName="p">{`!important`}</inlineCode>{`, extreme nesting, or unconventional methods, there should always be comments explaining why it was necessary`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      